import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Logo from "../../content/assets/coding-jewels-std-logo.png"
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement()

class IndexPage extends React.Component {
  render() {
    return (
      <Layout style={{ display: 'inline-block', margin: '0 auto', textAlign: 'center' }} location={this.props.location}>
        <div style={{ textAlign: 'center' }}>
          <SEO
            title="Home"
            keywords={[`blog`, `gatsby`, `javascript`, `react`]}
          />
          <img style={{ borderRadius: '25%', boxShadow: '0 10px 20px black', marginTop: '75px', height: '60%', width: '60%' }} src={Logo} alt="Coding Jewels Logo" />

          <h3>A Programming Blog by Whitney Lampkin</h3>
          <p>Happy Coding! &#10084;&#65039;</p>
          <Link to="/blog/" style={{ display: 'inline-block' }}>
            <Button marginTop="35px">Start Reading</Button>
          </Link>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
